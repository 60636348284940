import { create } from 'zustand';

export enum Currency {
  CHF = 'CHF',
  EUR = 'EUR',
  GBP = 'GBP',
  USD = 'USD',
}
type Team = {
  teamId: number;
  currency: Currency;
  numberFormat: string;
};
type CurrencyStore = {
  currency: Currency;
  numberFormat: string;
  usingOverride: boolean;
  setTeam: (team: Team) => void;
  setCurrencyOverride: (teamId: number, currency: Currency) => void;
};

const buildTeamCurrencyOverrides = () => {
  const SESSION_STORAGE_KEY = 'team_currency_overrides';
  const state = JSON.parse(window.localStorage.getItem(SESSION_STORAGE_KEY)) || {};
  return {
    get: (teamId: number) => state[teamId],
    set: (teamId: number, currency: Currency) => {
      state[teamId] = currency;
      window.localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(state));
    },
  };
};
const teamCurrencyOverrides = buildTeamCurrencyOverrides();

const getCurrencyNumberFormat = (currency: Currency): string => {
  switch (currency) {
    case Currency.CHF:
      return 'de-CH';
    case Currency.EUR:
      return 'de-DE';
    case Currency.GBP:
      return 'en-GB';
    case Currency.USD:
      return 'en-US';
  }
};
const getNumberFormat = (team: Team) => {
  const currencyOverride = teamCurrencyOverrides.get(team.teamId);
  if (currencyOverride) return getCurrencyNumberFormat(currencyOverride) || DEFAULTS.numberFormat;
  return team.numberFormat;
};
const DEFAULTS = {
  currency: Currency.CHF,
  numberFormat: getCurrencyNumberFormat(Currency.CHF),
};

export const useCurrencyStore = create<CurrencyStore>((set) => ({
  currency: DEFAULTS.currency,
  numberFormat: DEFAULTS.numberFormat,
  usingOverride: false,
  setTeam: (team) =>
    set({
      currency: teamCurrencyOverrides.get(team.teamId) || team.currency || DEFAULTS.currency,
      numberFormat: getNumberFormat(team),
      usingOverride: Boolean(teamCurrencyOverrides.get(team.teamId)),
    }),
  setCurrencyOverride: (teamId, currency) => {
    teamCurrencyOverrides.set(teamId, currency);
    set({
      currency,
      numberFormat: getCurrencyNumberFormat(currency) || DEFAULTS.numberFormat,
      usingOverride: true,
    });
  },
}));
